<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-bg"></div>
      <div class="login-form">
        <div class="login-title">账号登录</div>
        <el-form :model="LoginForm"
                 ref="LoginRef"
                 :rules="LoginRules">
          <el-form-item prop="Account">
            <el-input v-model.trim="LoginForm.Account"
                      placeholder="请输入账号"></el-input>
          </el-form-item>
          <el-form-item prop="PassWord">
            <el-input v-model.trim="LoginForm.PassWord"
                      show-password
                      type="password"
                      placeholder="请输入密码（6-20数字、字母组合）"></el-input>
          </el-form-item>
        </el-form>
        <div class="login-Agreement">
          <el-checkbox v-model="Agreement">我已阅读并同意</el-checkbox><span>《用户协议》</span>
        </div>
        <div class="login-btn">
          <el-button type="base"
                     @click="Login">立即登录</el-button>
          <div @click="$router.push('/register')">免费注册</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['reload'],
  data () {
    return {
      Agreement: false,
      passwordType: 'password',
      LoginForm: {
        Account: '',  // 16754879474
        PassWord: '',  // cs123456
        Source: '1',  //  1：官网   2：客户端
      },
      LoginRules: {
        Account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        PassWord: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
    }
  },
  methods: {
    // 登录
    Login () {
      this.$refs.LoginRef.validate(async bool => {
        if (!bool) return
        if (!this.Agreement) return this.$message.warning('请勾选同意用户协议')
        const { data: res } = await this.$axios.post('/api/Merchant/DBBMerchantLogin', this.LoginForm)
        if (res.nResult != 1) return
        window.sessionStorage.setItem('token', res.Data.Token)
        window.sessionStorage.setItem('userName', this.LoginForm.Account)
        window.sessionStorage.setItem('routerName', '/yunServices')
        this.$router.push('/yunServices')
        setTimeout(() => {
          location.reload()
        }, 500);
      })
    },

    // 密码
    // showPwd () {
    //   if (this.passwordType === 'password') {
    //     this.passwordType = ''
    //   } else {
    //     this.passwordType = 'password'
    //   }
    // },
  },
}
</script>

<style lang="less" scoped>
.login-page {
  background: linear-gradient(132deg, #e9fff9 0%, #caefff 100%);
  padding: 120px 0 60px 0;
}
.login-box {
  width: 1000px;
  height: 520px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin: 0 auto;
  overflow: hidden;
  .login-bg {
    float: left;
    width: 500px;
    height: 520px;
    background-image: url('../assets/images/pc/loginbg.png');
    background-repeat: no-repeat;
    background-size: 86% 100%;
    background-position-x: 60px;
  }
  .login-form {
    float: right;
    text-align: left;
    width: 425px;
    .login-title {
      font-size: 20px;
      font-weight: 600;
      color: #000000;
      position: relative;
      margin: 70px 0 50px 0;
    }
    .login-title::after {
      content: '';
      width: 80px;
      height: 4px;
      background: #3cb371;
      position: absolute;
      left: 0;
      bottom: -10px;
    }
    .el-input {
      width: 340px;
    }
    .el-form-item {
      margin-bottom: 30px;
    }
    .login-Agreement {
      span {
        font-size: 14px;
        color: #3b7cff;
        cursor: pointer;
      }
    }
  }
}

.login-btn {
  .el-button {
    width: 340px;
    margin: 60px 0 10px 0;
  }
  div {
    width: 340px;
    font-size: 14px;
    cursor: pointer;
    color: #3cb371;
    text-align: center;
  }
}
</style>
